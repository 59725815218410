import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../../../../../components/Layout';
import Seo from '../../../../../components/SEO';
import QuestionSection from '../../../../../components/QuestionSection';
import {ConnectorIntro, ConnectorOverview, SeeAllArrow} from '../../../../../components/systems';
import {StaticImage} from 'gatsby-plugin-image';

const Okta = () => {
  const connectorName = 'okta';
  const connectorImages = useStaticQuery(graphql`
    query {
      allFile(filter: {absolutePath: {regex: "/systems/okta/"}, extension: {regex: "/(jpg)|(png)/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
              fluid(maxWidth: 800, quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `);
  const edgesImages = connectorImages.allFile.edges;
  const images = {
    imageOverview: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-overview`),
    imageLogo: edgesImages.filter((edge) => edge.node.name === `${connectorName}`),
  };

  const connector = {
    intro: {
      logo: images.imageLogo,
      name: 'Okta',
      description: `<u>Okta</u> is an industry-leading Identity Provider offer strong authentication product 10s of thousands of businesses worldwide. The platform is cloud based and supports all the modern identity standards for Single Sign-on (SSO)—such as OpenID Connect (OIDC) and Security Assertion Markup Language (SAML). The company also offers products for multi-factor authentication (MFA) and entitlement management. <br><br>
As an IdP, Okta stores important identity data for the users of the system. For example, Okta stores information about reporting structure, contact information, and mappings to connected apps. These data is extremely valuable for making policy decisions for entitlements.`,
    },
    overview: {
      title: 'Trustle w/ Okta',
      overview: 'Overview',
      description: `Trustle can use IdPs (including Google and Okta) for SSO into Trustle. However, that operation is separate from the Okta connector. Trustle connects to Okta’s API and imports the user identity and relevant profile, group, role, title, and manager information. Users don’t need to login to Trustle before having their user data made available in Trustle. Because Okta can source this information from trusted systems like Microsoft Active Directory (AD), Trustle can also use this information in policy and administration operations.
<br><br>Using Okta with Trustle makes it even easier to link user accounts, even on GitHub, where handles usually aren’t reflective of corporate profiles. In addition, Trustle users can view Okta resources such as groups and roles and request access; Trustle then routs the approval request appropriately and also determines whether the access is temporary or just-in-time, as needed.
<br><br>Trustle also makes recommendations for the security of your Okta implementation. For example, Trustle can discover unused and risky accounts and establish baseline usage patterns for members of the same teams. 
<br><br>Trustle’s Okta connector is informative and easy to use. You can easily review existing applications and permissions access, as well as assign and modify access to your users with just a few clicks.`,
    },
  };

  const {intro, overview} = connector;

  return (
    <Layout
      seo={
        <Seo
          title="Okta System | Trustle"
          description="Trustle’s Okta connector is informative and easy to use. You can easily review existing applications and permissions access, as well as assign and modify access to your users with just a few clicks."
        />
      }
    >
      <section className="pt-16 overflow-hidden transform bg-gradient-to-b from-gray-200 via-white to-gray-200">
        <div className="flex items-center px-4 pb-12 md:py-8 lg:mx-auto max-w-[1360px] font-bold text-sm md:text-xl text-blue">
          <SeeAllArrow />
        </div>

        <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center">
          {connector && (
            <>
              <ConnectorIntro name={intro?.name} description={intro?.description} logo={intro?.logo} isPageHeader />
              <ConnectorOverview title={overview?.title} description={overview?.description} />
              <div className="mx-8">
                <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">Okta and System Permissions</h2>
                <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                  Trustle’s Okta connector gathers Okta configuration data about your users, enabling you to view users’
                  role assignments and permissions. This enables you to monitor and audit all the changes in admin role
                  assignments, as well as managing custom and standard roles assigned to users.
                </p>
                <div className="pb-8 mb-8">
                  <StaticImage
                    src="../../../../../images/systems/okta/system-okta-overview.png"
                    alt="System Permission Dashboard"
                    className="drop-shadow-xl md:drop-shadow-2xl"
                    placeholder="blurred"
                    width={1132}
                  />
                </div>

                <div className="flex flex-col items-center justify-between mb-8 md:mb-16 xl:flex-row">
                  <div className="pb-8 max-w-[604px]">
                    <StaticImage
                      src="../../../../../images/systems/okta/system-okta-feature-01.png"
                      alt="System Applications Dashboard"
                      className="drop-shadow-xl md:drop-shadow-2xl"
                      placeholder="blurred"
                      width={730}
                    />
                  </div>
                  <div className="pb-8">
                    <div className="max-w-[530px]">
                      <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl no-wrap">Okta and Applications</h2>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        Trustle allows you to manage access to external applications and systems securely.  The Okta
                        connector provides a comprehensive view of all available application integrations that your
                        users access via SSO. It provides the ability to effectively manage user assignments, and ensure
                        that only authorized personnel have access to sensitive information.
                      </p>
                    </div>
                  </div>
                </div>

                <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">Okta Directory Groups</h2>
                <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                  Directory Groups simplifies access management, as changes to group access settings are automatically
                  applied to all members of the group.  Through groups, your organization remains secure and accessible
                  to all assigned users.<br></br>
                  <br></br> Trustle’s Okta connector allows you to easily manage Okta group memberships, as well as
                  quickly gain insights on permissions associated with each group, including applications to which they
                  have access and associated roles.
                </p>
                <div className="pb-8 mb-8">
                  <StaticImage
                    src="../../../../../images/systems/okta/system-okta-overview.png"
                    alt="Directory Group Dashboard"
                    className="drop-shadow-xl md:drop-shadow-2xl"
                    placeholder="blurred"
                    width={1132}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <QuestionSection />
    </Layout>
  );
};
export default Okta;
